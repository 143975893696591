<template>
  <router-view></router-view>
</template>

<script>
import { ADD_MENU_TYPE } from "@/core/services/store/system/menu.module";

export default {
  name: "Templates",

  mounted() {
    this.setMenuType();
  },

  methods: {
    setMenuType() {
      this.$store.dispatch(ADD_MENU_TYPE, "root").then();
    }
  }
}
</script>